import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { urlPath } from 'src/utils/lpPath'

const LpFooter: React.FC = () => (
  <footer className="LPFooter" id="footer">
    <div className="LPFooterLogo">
      <Image
        layout="raw"
        src="/images/logo/logo_blueback.png"
        alt="=@葬儀（アット葬儀）"
        width="344"
        height="154"
      />
    </div>
    <div className="LPFooterLinkArea">
      <div className="LPFooterLink">
        <Link href={urlPath.sitePolicy}>
          <a>サイトポリシー</a>
        </Link>
      </div>
      <div className="column-border LPFooterLink">
        <a href={urlPath.privacy} target="_blank" rel="noopener noreferrer">
          プライバシポリシー
        </a>
      </div>
      <div className="LPFooterLink">
        <a href={urlPath.company} target="_blank" rel="noopener noreferrer">
          運営会社
        </a>
      </div>
    </div>
    <div className="LPFooterCopyright">Copyright©︎ Microwave Co.All rights reserved</div>
  </footer>
)

export default LpFooter
