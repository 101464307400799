import '@/styles/newLP.css'
import '@/styles/tailwind.css'
import '@/styles/footer.css'
import '@/styles/globals.css'
import '@/styles/blockUI.css'
import '@/styles/contact.css'
import '@/styles/interview.css'

import type { AppProps } from 'next/app'

import LpFooter from '@/components/parts/lpPage/LpFooter'

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Component {...pageProps} />
      <LpFooter />
    </>
  )
}

export default MyApp
